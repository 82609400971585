<template>
  <div class="otherArtworkContainer">
    <div v-if="otherArtwork.length >= 1">
      <h3 style="font-weight: bold" :class="$screen.width >= 1264 ? `ma-4` : 'ma-1'">
        {{ inMyCollection ? this.$t("collectionHeading") : $t("artworkSimilar") }}
      </h3>

      <div class="scrollBarWrapper">
        <div
          v-for="item in otherArtwork.filter((e) => e.coverPhotos)"
          :key="item.workid"
          class="imageContainer"
          style="cursor: pointer"
        >
          <v-hover v-slot="{ hover }">
            <div :class="$screen.width >= 1264 ? `mx-${hover ? 3 : 4} my-2` : 'ma-1'" class="transition-swing">
              <div @click="viewArtwork(item.workid)">
                <v-lazy min-height="150px">
                  <image-from-file-descriptor
                    :fileDescriptor="item.coverPhotos[0]"
                    :key="item.coverPhotos[0].fileid"
                    :class="`elevation-${hover ? 4 : 0}`"
                    class="transition-swing"
                    style="border-radius: 10px"
                  />
                </v-lazy>
              </div>

              <h4 v-if="!noTitle(item)" style="margin-top: 15px; padding: 0px; font-weight: bold">
                {{ item.workInfo.artworkTitle }}
              </h4>
              <h4 v-else style="margin-top: 15px; padding: 0px; font-weight: bold; color: #9e9e9e">
                <i>{{ $t("artworkUntitled") }}</i>
              </h4>
              <name-tag-from-address :address="item.creatorAddress" style="margin-bottom: 15px" />
              <div class="chips">
                <div
                  class="greenChip"
                  v-for="(hashtag, i) in item.unmanagedInfo.hashTags"
                  :key="i"
                  @click="hashtagSearch(hashtag)"
                >
                  {{ hashtag }}
                </div>
              </div>
            </div>
          </v-hover>
        </div>
      </div>

      <!-- <div style="box-sizing: border-box; max-width: 80vw" v-else-if="!loading">
        <v-slide-group v-model="carouselPosition">
          <v-slide-item v-for="item in otherArtwork" :key="item.workid">
            <div style="width: 135px" @click="viewArtwork(item.workid)">
              <div><image-from-file-descriptor :fileDescriptor="item.coverPhoto" /></div>
              <h4 style="font-weight: bold">
                {{ item.workInfo.artworkTitle }}
              </h4>
              <div class="chips">
                <v-chip
                  class="py-0 px-1 mr-2"
                  color="secondary"
                  label
                  small
                  v-for="(hashtag, i) in item.unmanagedInfo.hashTags"
                  :key="i"
                  ><p>{{ hashtag }}</p></v-chip
                >
              </div>
            </div>
          </v-slide-item>
        </v-slide-group> 
      </div>-->
    </div>
    <div
      :style="{ padding: $screen.width > 600 ? '32px' : '16px' }"
      style="display: flex; justify-content: center; align-items: center; height: 60vh"
      v-if="loading"
    >
      <v-progress-circular size="60" indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import imageFromFileDescriptor from "../Common/ImageFromFileDescriptor.vue";
import config from "@/config";
import NameTagFromAddress from "../../components/Common/NameTagFromAddress.vue";

export default {
  components: { imageFromFileDescriptor, NameTagFromAddress },
  props: ["displayItem", "loading"],
  created() {
    if (this.$store.state.bazaarList.length === 0) {
      this.getWorkList();
    }
  },
  data: () => ({
    carouselPosition: 0,
    bazaarList: []
  }),
  computed: {
    inMyCollection() {
      return this.$route.name === "My Private Work";
    },
    otherArtwork() {
      if (!this.displayItem) {
        return [];
      }
      if (this.inMyCollection) {
        const workList = this.$store.state.workList;
        const filterWorkList = workList.filter((elem) => {
          return elem.workid !== this.displayItem.workid;
        });
        return filterWorkList;
      } else {
        return this.$store.getters.getSimilarArtworks(
          this.displayItem.workid,
          this.displayItem.workInfo.artworkType,
          this.displayItem.unmanagedInfo.artworkClass,
          12
        );
      }
    }
  },
  methods: {
    viewArtwork(workid) {
      this.$store.commit("setEventName", null);
      this.$store.dispatch("getEventBazaarList", { axios: this.$axios, config: config, eventName: null });
      if (this.inMyCollection) {
        this.$router.push(`/platform/mycollection/${workid}`).catch((e) => e);
      } else {
        if (this.workid !== workid) {
          this.$router
            .push({
              name: "ViewArtwork",
              params: {
                workid: workid
              }
            })
            .catch((e) => e);
          this.$emit("toOtherArtwork");
        }
      }
    },
    async getWorkList() {
      this.$store.commit("showProgressLine", true);
      const token = this.$session.get("token");
      await this.$store.dispatch("getBazaarList", {
        axios: this.$axios,
        config,
        token
      });
      this.$store.commit("showProgressLine", false);
    },
    hashtagSearch(hashtag) {
      this.$store.commit("setEventName", null);
      this.$store.dispatch("getEventBazaarList", { axios: this.$axios, config: config, eventName: null });
      this.$router.push({
        path: `/platform/bazaar?hashtag=${hashtag}`
      });
    },
    noTitle(item) {
      if (!item.workInfo.artworkTitle) {
        return true;
      }
      if (!item.workInfo.artworkTitle.replace(/\s/g, "").length) {
        return true;
      }
      return false;
    }
  }
};
</script>
