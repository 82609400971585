<template>
  <div id="displayCard">
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div class="addArtwork">
      <div class="ma-0 pa-0 newArtworkContainer">
        <artwork-display
          :displayItem="displayWork"
          v-if="!loading && displayWork"
          id="artworkDisplay"
          @refreshItem="getDisplayItem"
        />
        <div id="commentBox" class="platformCard" v-if="comments">
          <fast-comments-vue :urlId="workid" />
        </div>
        <other-artwork
          :displayItem="displayWork"
          id="otherArtwork"
          :loading="loading"
          v-if="!(loading && $screen.width < 600)"
        />
        <v-card
          class="d-flex"
          id="artworkDisplay"
          style="padding: 32px"
          v-if="loading || !displayWork"
          min-height="80vh"
        >
          <v-row>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image,  list-item-avatar-three-line"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="9">
              <v-skeleton-loader
                type="article, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, actions"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import artworkDisplay from "@/components/Platform/ArtworkDisplay";
import otherArtwork from "@/components/Platform/OtherArtwork";
import MessageBox from "@/components/Common/MessageBox";
import PlatformLayout from "@/layouts/Platform";
import { displayWorkByWorkid, retrieveLastUpdate } from "../../services/workService";
import FastCommentsVue from "../../components/Common/FastComments.vue";

export default {
  name: "ViewArtwork",
  props: ["editedItem"],
  data: () => ({
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    displayItem: null,
    loading: false,
    work: null
  }),
  components: {
    artworkDisplay,
    otherArtwork,
    MessageBox,
    FastCommentsVue
  },
  created() {
    this.getDisplayItem();
    this.setBackButton();

    this.$emit(`update:layout`, PlatformLayout);
  },
  watch: {
    $route() {
      this.getDisplayItem();
    }
  },
  beforeDestroy() {
    this.$store.commit("showProgressLine", false);
    // this.$store.commit("hideBackDestination");
  },
  methods: {
    setBackButton() {
      if (this.$store.state.backDestination.override) {
        return;
      }
      if (this.$route.name === "My Private Work") {
        this.$store.commit("setBackDestination", {
          show: true,
          path: "/platform/mycollection",
          name: this.$t("titleMyCollection")
        });
      } else {
        this.$store.commit("setBackDestination", {
          show: true,
          path: "/platform/bazaar",
          name: this.$t("titleBazaar")
        });
      }
    },
    upload() {
      this.$emit("uploadToMarket");
    },
    editFile() {
      this.$emit("editFile");
    },
    async getDisplayItem() {
      this.loading = true;
      if (this.$route.name === "My Private Work") {
        if (retrieveLastUpdate(this.workid, new Date().getTime()) >= 100) {
          await this.$store.dispatch("getWorkList", {
            axios: this.$axios,
            config: config,
            token: this.$session.get("token")
          });
        }
        this.setLocalWork();
      } else {
        const result = await displayWorkByWorkid(this.$axios, config, this.workid, this.$session.get("token"));
        if (result.status === 200) {
          this.displayItem = result.data;
          if (result.data.workInfo.artworkTitle) {
            document.title = result.data.workInfo.artworkTitle.concat(" - ").concat(this.$t("tectechainName"));
          }
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.messageBox.destination = "/platform/bazaar";
        }
      }

      this.loading = false;
    },
    setLocalWork() {
      let workList = this.$store.state.workList;
      let item = workList.find((elem) => elem.workid === this.workid);
      if (item) {
        this.work = item;
        if (item.workInfo.artworkTitle) {
          document.title = this.work.workInfo.artworkTitle.concat(" - ").concat(this.$t("tectechainName"));
        }
      } else {
        this.$router.push("/platform/mycollection").catch((e) => e);
      }
    }
  },
  computed: {
    workid() {
      return this.$route.params.workid;
    },

    displayWork() {
      if (this.displayItem) {
        return this.displayItem;
      } else {
        return this.work;
      }
    },
    comments() {
      return config.comments
    }
  }
};
</script>
