<template>
  <div class="fastComments">
    <fast-comments
      :key="`fastcomments-${locale}`"
      v-bind:config="{ tenantId: 'DI1IvcHUcUJB', urlId: urlId, simpleSSO: userData, locale: locale }"
    />
  </div>
</template>

<script>
import FastComments from "fastcomments-vue";
import config from "@/config.js";
export default {
  props: {
    urlId: {
      type: String,
      default: null
    }
  },
  components: {
    FastComments
  },
  computed: {
    isLogin() {
      return !!this.$session.get("token");
    },
    userData() {
      const userProfile = this.$store.state.userProfile;
      if (this.isLogin) {
        const user = {
          email: userProfile.email,
          username: userProfile.userid,
          websiteUrl: `${config.frontend}/platform/user/${userProfile.userid}`,
          displayName: userProfile.userid,
          displayLabel: this.$store.state.userProfile.isPremium ? "Premium User" : "",
          loginURL: `${config.frontend}/login`,
          logoutCallback: this.logout
        };
        return user;
      } else {
        return {
          loginURL: `${config.frontend}/login`,
          logoutCallback: this.logout
        };
      }
    },
    locale() {
      if (this.$i18n.locale === "zhHant") {
        return "zh_tw";
      } else if (this.$i18n.locale === "zhHans") {
        return "zh_cn";
      } else {
        return "en_us";
      }
    }
  },
  mounted() {},
  methods: {
    logout: function () {
      this.$router.push("/", () => {
        this.$store.dispatch("clearImageURL");
        this.$session.destroy();
        this.$store.commit("addr", null);
        this.$store.commit("changeState", false);
        this.$store.commit("refreshUserProfile", {});
        localStorage.removeItem("workLastUpdate");
        localStorage.removeItem("redirect");
        this.$store.dispatch("resetState");
      });
    }
  }
};
</script>

<style></style>
